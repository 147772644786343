import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { ServiceInfoProps } from "./Service.d"
import { Image } from "../../atoms/Image"
import { ButtonLink } from "../../atoms/Button"
import { CTA } from "../../../global"
import { Toyota } from "../../atoms/Logo"
import BlockContent from "../../atoms/BlockContent"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const ServiceInfo: React.FC<ServiceInfoProps> = ({ serviceSection }) => {
  const [state, dispatch] = useContext(DisclaimersContext)
  return (
    <section
      aria-label="Service Information Section"
      css={[
        tw`h-full bg-gray-100`,
        tw`grid grid-cols-1 `,
        tw`lg:(grid-cols-2)`,
      ]}
      id={serviceSection.sectionSlug?.current}
    >
      <div
        css={[
          tw`bg-gray-100`,
          tw`md:(h-[calc(100vh - 70px)])`,
          tw`xl:(h-[calc(100vh - 40px)])`,
          tw`2xl:(h-[calc(100vh - 70px)])`,
        ]}
      >
        <div
          css={[
            tw`col-span-1 flex gap-8 flex-col items-start px-8 mb-8 pt-8 h-full`,
            tw`lg:(px-10 py-20 bg-white justify-center)`,
            tw`xl:(pl-10)`,
            tw`2xl:(pl-40)`,
          ]}
        >
          {serviceSection.heading && (
            <div css={[tw`inline-flex items-center`]}>
              <Toyota showText={false} height="100px" width="100px" />
              <h2 css={[tw`font-semibold text-4xl ml-2`]}>
                {parseDisclaimerBlocks(serviceSection.heading, selection =>
                  dispatch(toggleDisclaimersModal(selection))
                )}
              </h2>
            </div>
          )}
          {serviceSection.body && <BlockContent data={serviceSection.body} />}
          {/* CTAs */}
          {serviceSection.ctas && (
            <div css={[tw`items-center`, tw`lg:(grid grid-cols-2 gap-4)`]}>
              {serviceSection.ctas?.map((cta: CTA) => {
                return (
                  <ButtonLink
                    {...{ [cta?.buttonType]: true }}
                    to={getCTALinkValue(cta)}
                    target={cta?.linkType === "external" ? "_blank" : "_self"}
                    css={[
                      tw`w-full whitespace-nowrap my-1`,
                      tw`sm:(w-full)`,
                      tw`lg:(w-auto col-span-1)`,
                    ]}
                    key={cta?.title}
                  >
                    {cta?.title}
                  </ButtonLink>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <div
        css={[
          tw`hidden`,
          tw`lg:(col-span-1 col-start-2 block)`,
          tw`md:(h-[calc(100vh - 70px)])`,
          tw`xl:(h-[calc(100vh - 40px)])`,
          tw`2xl:(h-[calc(100vh - 70px)])`,
        ]}
      >
        <Image
          imageData={serviceSection?.image.image}
          css={[tw`object-cover h-full w-full`]}
        />
      </div>
    </section>
  )
}

export default ServiceInfo
