import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import CtaBox from "./CtaBox"
import { Image } from "../../atoms/Image"
import useViewportSize from "../../../hooks/useViewportSize"
import Icon from "../../atoms/Icon"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import { Find } from "../Find"
import { Toyota } from "../../atoms/Logo"
import { HeaderProps } from "./Heros"
import { ImageWithAlt } from "../Callout/Callout.d"

const Header: React.FC<HeaderProps> = ({
  section,
  icon,
  headerTextSize = "large",
  descriptionTextSize = "small",
  findType = null,
  includeLogo = false,
  ...remainingProps
}) => {
  const {
    headerHeading,
    headerBackground,
    headerBackgroundMobile,
    headerSubHeading,
  } = section
  const [state, dispatch] = useContext(DisclaimersContext)
  const [backgroundImageSrc, setBackgroundImageSrc] = useState<ImageWithAlt>()
  const { width } = useViewportSize()
  const HeadingIcon = icon && Icon[icon]

  useEffect(() => {
    if (width <= 768 && headerBackgroundMobile) {
      setBackgroundImageSrc(headerBackgroundMobile)
    } else {
      setBackgroundImageSrc(headerBackground)
    }
  }, [width])

  return (
    <section
      css={[
        tw`flex flex-col h-auto bg-[#ccc] relative`,
        tw`md:(items-center flex-row h-[680px])`,
      ]}
      {...remainingProps}
    >
      {backgroundImageSrc && (
        <Image
          imageData={backgroundImageSrc}
          css={[
            tw`relative top-0 w-full h-[300px] object-cover`,
            "z-index: 0;",
            tw`md:(h-[680px] absolute top-0)`,
          ]}
        />
      )}
      <div
        css={[
          tw`ml-0 w-full z-20 bg-white p-6`,
          tw`md:(p-10 max-w-[620px] mx-auto rounded-2xl bg-opacity-90)`,
          tw`lg:(p-10 w-auto ml-20 mb-32 rounded-2xl bg-opacity-90)`,
        ]}
      >
        <h1
          css={[
            tw`text-xl font-semibold mb-4 flex items-center`,
            headerTextSize === "large" && tw`md:(text-4xl mb-4)`,
            headerTextSize === "small" && tw`md:(text-xl mb-4)`,
          ]}
        >
          {includeLogo && <Toyota showText={false} css={[tw`mr-3`]} />}
          {HeadingIcon && <HeadingIcon css={[tw`h-8 mr-2 md:(h-12)`]} />}
          {parseDisclaimerBlocks(headerHeading, selection =>
            dispatch(toggleDisclaimersModal(selection))
          )}
        </h1>
        {headerSubHeading && (
          <h4
            css={[
              tw`text-base tracking-widest inline font-light leading-normal mb-0`,
              descriptionTextSize === "large" && tw`md:(text-2xl mb-6)`,
              descriptionTextSize === "small" && tw`md:(text-lg mb-6)`,
            ]}
          >
            {parseDisclaimerBlocks(headerSubHeading, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h4>
        )}
      </div>
      <div
        css={[
          tw`border-t border-b bg-white relative -ml-12 overflow-hidden bottom-auto right-auto md:(absolute bottom-10 right-20 z-10 border-0 bg-transparent) lg:(right-40)`,
        ]}
      >
        {findType && <Find type={findType} css={[tw`md:(shadow-4)`]} />}
      </div>
    </section>
  )
}

export default Header
