import React, { useContext, useEffect, useRef, useState } from "react"
import tw from "twin.macro"
import { VideoSectionProps } from "./Video.d"
import { Image } from "../../atoms/Image"
import { Button } from "../../atoms/Button"
import Icon from "../../atoms/Icon"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import ExternalVideo from "./ExternalVideo"
import VideoS3 from "./VideoS3"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const VideoSection: React.FC<VideoSectionProps> = ({
  videoSectionBackground,
  videoSectionSubheading,
  videoSectionTitle,
  videoSectionHeading,
  videoSectionCTAText,
  videoSectionVideo,
  video_s3,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { _ } = useContext(LanguageContext)

  function handleClose() {
    setModalOpen(!modalOpen)
  }
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <section
      aria-label="Video Section"
      css={[
        tw`flex h-auto bg-[#ccc] relative`,
        tw`md:(items-center h-[680px])`,
      ]}
    >
      {videoSectionBackground && (
        <Image
          imageData={videoSectionBackground}
          css={[
            tw`hidden absolute top-0 w-full h-auto object-cover`,
            "z-index: 0;",
            tw`md:(h-[680px] flex )`,
          ]}
        />
      )}
      <div
        css={[
          tw`ml-0 w-full z-20 bg-white bg-opacity-90 p-10`,
          tw`md:(w-auto ml-20 mb-60 rounded-2xl max-w-[60ch])`,
        ]}
      >
        <span css={[tw`uppercase block font-semibold mb-4`]}>
          {parseDisclaimerBlocks(
            videoSectionTitle ?? _("Watch Video"),
            selection => modalDispatch(toggleDisclaimersModal(selection))
          )}
        </span>
        <h3 css={[tw`text-xl font-semibold mb-4`, tw`md:(text-2xl mb-4)`]}>
          {parseDisclaimerBlocks(videoSectionHeading, selection =>
            modalDispatch(toggleDisclaimersModal(selection))
          )}
        </h3>
        {videoSectionSubheading ? (
          <h4
            css={[
              tw`text-2xl tracking-widest font-light leading-normal mb-6`,
              tw`md:(text-3xl mb-6)`,
            ]}
          >
            {parseDisclaimerBlocks(videoSectionSubheading, selection =>
              modalDispatch(toggleDisclaimersModal(selection))
            )}
          </h4>
        ) : null}
        <Button secondary onClick={() => setModalOpen(true)}>
          {videoSectionCTAText ?? _("Watch Now")}
        </Button>
      </div>
      {modalOpen && (
        <div
          css={[
            tw`fixed top-0 bottom-0 w-full bg-[#000] h-full text-white p-12 pt-24`,
            "z-index: 60",
          ]}
        >
          <button
            onClick={() => handleClose()}
            css={[tw`flex items-center text-xs uppercase`]}
          >
            <Icon.Chevron
              color="white"
              direction="left"
              css={[tw`max-h-4 mr-2 w-4`]}
            />
            {_("BACK")}
          </button>
          {videoSectionVideo && !video_s3 && (
            <ExternalVideo
              videoSectionHeading={videoSectionHeading}
              videoSectionVideo={videoSectionVideo}
            />
          )}
          {video_s3 && (
            <VideoS3
              videoSectionHeading={videoSectionHeading}
              video_s3={video_s3}
            />
          )}
          <div css={tw`text-white`}>
            <h2 css={[tw`text-base font-semibold`]}>
              {parseDisclaimerBlocks(videoSectionHeading, selection =>
                modalDispatch(toggleDisclaimersModal(selection))
              )}
            </h2>
            <h4
              css={[
                tw`text-base tracking-widest font-light leading-normal mb-6`,
              ]}
            >
              {_("Peace Of.")}
              <br />
              {_("Mind Comes.")}
              <br />
              {_("Standard.")}
            </h4>
          </div>
        </div>
      )}
    </section>
  )
}

export default VideoSection
