import React, { useContext } from "react"
import tw from "twin.macro"
import { LanguageContext } from "../../../contexts/Language"
import { Accordion } from "../../atoms/Accordion"
import { Toyota } from "../../atoms/Logo"
import { BenefitProps } from "./Benefits.d"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const Benefits: React.FC<BenefitProps> = ({ benefits, title }) => {
  const { _ } = useContext(LanguageContext)
  return (
    <div css={[tw`mx-8 mt-8`, tw`md:(max-w-5xl mx-auto my-20)`]}>
      <h3
        css={[
          tw`flex items-center text-xl justify-between font-semibold mb-4`,
          tw`md:(text-xl items-center justify-start)`,
        ]}
      >
        <span css={[tw`flex items-center`]}>
          <Toyota showText={false} css={[tw`mr-3`]} />
          {title}
        </span>
        <span
          css={[tw`font-light tracking-widest pl-3 text-xl md:(text-2xl ml-4)`]}
        >
          {_("Benefits")}
        </span>
      </h3>
      <div css={[tw`border-b border-gray-500`]}>
        {benefits?.map((benefit: { heading: string; description: string }) => (
          <Accordion
            expanded={false}
            label={benefit.heading}
            css={[
              tw`border-t items-start border-gray-500 px-5 pl-0 py-6 mt-0`,
              tw`md:(px-5 py-6 mt-0)`,
            ]}
          >
            <ul css={[tw`block text-left mr-7`]}>
              <li css={[tw`ml-0 mb-4 md:(ml-6)`]}>{benefit.description}</li>
            </ul>
          </Accordion>
        ))}
      </div>
    </div>
  )
}

export default Benefits
