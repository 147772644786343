import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import { ServicesProps } from "./Service.d"
import { Image } from "../../atoms/Image"
import Icon from "../../atoms/Icon"
import { ButtonLink } from "../../atoms/Button"
import { CTA } from "../../../global"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { LanguageContext } from "../../../contexts/Language"
import { getCTALinkValue, parseDisclaimerBlocks } from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { Toyota } from "../../atoms/Logo"

/**
 *
 * @author Cody
 * @summary
 *
 *
 */

const Services: React.FC<ServicesProps> = ({
  servicesBackground,
  servicesHeading,
  servicesSubHeading,
  servicesDescription,
  services,
}) => {
  const [selectedService, setSelectedService] = useState(services[0])
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(0)
  const [_disclaimerState, dispatch] = useContext(DisclaimersContext)
  const { _ } = useContext(LanguageContext)

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const [state, modalDispatch] = useContext(DisclaimersContext)
  return (
    <section aria-label="Toyota Services Section">
      <div
        css={[
          tw`flex bg-[#ccc] h-[520px] relative py-10 overflow-hidden`,
          tw`md:(items-center h-[500px])`,
          tw`lg:(items-center h-[500px])`,
        ]}
      >
        <div
          css={[
            tw`bg-gray-900 bg-opacity-60 z-10 w-full h-full absolute top-0`,
          ]}
        ></div>
        {servicesBackground && (
          <Image
            imageData={servicesBackground.image}
            css={[
              tw`absolute top-0 w-full h-[520px] object-cover`,
              "z-index: 0;",
              tw`md:(h-auto w-full absolute top-0 bg-cover)`,
            ]}
          />
        )}
        <div css={[tw`flex items-center max-w-7xl mx-auto z-10`]}>
          <div
            css={[
              tw`w-full z-20 text-white px-4`,
              tw`md:(w-full)`,
              tw`lg:(w-full)`,
            ]}
          >
            <h1
              css={[
                tw`text-xl font-semibold mb-4 flex items-center`,
                tw`sm:(text-4xl mb-4)`,
              ]}
            >
              <Toyota showText={false} css={[tw`mr-3`]} />
              {parseDisclaimerBlocks(servicesSubHeading, selection =>
                modalDispatch(toggleDisclaimersModal(selection))
              )}
            </h1>
            <h3
              css={[
                tw`text-4xl font-light mb-0 flex items-center`,
                tw`md:(text-5xl font-light mb-4 w-4/5)`,
                tw`lg:(pl-0)`,
              ]}
            >
              {parseDisclaimerBlocks(servicesHeading, selection =>
                modalDispatch(toggleDisclaimersModal(selection))
              )}
            </h3>
          </div>
          {/*<div
            css={[
              tw`text-white text-sm z-20 col-span-2 px-6 mx-auto md:(col-span-1 text-base)`,
            ]}
          >
            {servicesDescription}
          </div>*/}
        </div>
      </div>
      <div>
        <div css={[tw`bg-gray-100`]}>
          <div
            css={[
              tw`relative items-center justify-center max-w-7xl mx-auto -mt-12`,
              tw`md:(-mt-20)`,
            ]}
          >
            <div
              css={[
                tw`flex items-baseline overflow-x-scroll pb-6 md:(items-baseline mx-auto max-w-7xl) scrollbar-hide`,
              ]}
            >
              {services.map((service, index) => {
                const TabIcon = Icon[service?.icon]
                return (
                  <button
                    css={[
                      tw`text-xs flex-none block font-book tracking-widest text-white py-0 !px-0 text-center z-10 w-[200px]`,
                      tw`md:(text-base w-[240px])`,
                      selectedService &&
                        selectedService.name === service?.name &&
                        tw`flex-none rounded-t-xl px-4`,
                      tw`md:(rounded-t-xl text-center py-6 px-8)`,
                    ]}
                    aria-selected={selectedService.name === service?.name}
                    onClick={() => {
                      setSelectedServiceIndex(index)
                      setSelectedService(service)
                      trackTealEvent({
                        tealium_event: "carousel_click",
                        carousel_action: service?.name,
                      })
                    }}
                  >
                    <span
                      css={[
                        tw` border-b-4 border-b-transparent pb-1 mb-4 inline-block`,
                        selectedService &&
                          selectedService.name === service?.name &&
                          tw`border-b-red-400`,
                      ]}
                    >
                      {service?.title}
                    </span>
                    {TabIcon && (
                      <TabIcon
                        color="red-400"
                        css={[
                          tw`h-20 w-32 mx-auto pb-2 hidden`,
                          selectedService &&
                            selectedService.name === service?.name &&
                            tw`flex-none block p-5 bg-gray-50 shadow-4 mt-2`,
                        ]}
                      />
                    )}
                  </button>
                )
              })}
            </div>
            {selectedService && (
              <>
                <div
                  css={[
                    tw`relative z-20 grid grid-cols-2 min-h-[450px] grid-rows-1 grid-flow-row w-auto p-6 px-8 mb-10 mx-8`,
                    tw`md:(-top-2 gap-24 py-16 min-h-[350px] mx-40)`,
                    tw`xl:(mx-20)`,
                  ]}
                >
                  <div
                    css={[tw`hidden xl:(p-4 text-center items-center block)`]}
                  >
                    {selectedService?.image && (
                      <Image imageData={selectedService?.image} css={[tw``]} />
                    )}
                  </div>
                  <div
                    css={[tw`leading-normal col-span-2`, tw`xl:(col-span-1)`]}
                  >
                    <h4 css={[tw`mb-4 font-semibold text-sm`]}>
                      {_("Express Maintenance Service")}
                    </h4>
                    <h3
                      css={[
                        tw`font-semibold uppercase text-3xl tracking-widest pb-2`,
                      ]}
                    >
                      {parseDisclaimerBlocks(selectedService.name, selection =>
                        dispatch(toggleDisclaimersModal(selection))
                      )}
                    </h3>
                    {parseDisclaimerBlocks(
                      selectedService.description,
                      selection => dispatch(toggleDisclaimersModal(selection))
                    )}
                    {/* CTAs */}
                    {selectedService.ctas && (
                      <div css={[tw`mt-6 lg:(flex gap-2)`]}>
                        {selectedService.ctas?.map((cta: CTA) => {
                          return (
                            <ButtonLink
                              {...{ [cta?.buttonType]: true }}
                              to={getCTALinkValue(cta)}
                              target={
                                cta?.linkType === "external"
                                  ? "_blank"
                                  : "_self"
                              }
                              css={[
                                tw`w-full col-span-1 whitespace-nowrap text-xs my-1 !px-0`,
                                tw`sm:(!px-4)`,
                                tw`md:(!px-0)`,
                                tw`lg:(w-auto !px-4)`,
                                tw`xl:(w-auto !px-8)`,
                              ]}
                              key={cta?.title}
                            >
                              {cta?.title}
                            </ButtonLink>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {/* Slider Controls */}

            <button
              css={[
                tw` bg-transparent absolute top-1/2 left-2 mt-6 transform -translate-y-1/2 z-20 lg:(left-0) hover:(bg-gray-400 bg-opacity-75) focus:(outline-white bg-gray-400 bg-opacity-75) disabled:(opacity-0)`,
              ]}
              onClick={() => {
                if (selectedServiceIndex !== 0) {
                  setSelectedServiceIndex(selectedServiceIndex - 1)
                  setSelectedService(services[selectedServiceIndex - 1])
                  trackTealEvent({
                    tealium_event: "carousel_click",
                    carousel_action: "left arrow",
                  })
                } else {
                  setSelectedServiceIndex(services.length - 1)
                  setSelectedService(services[services.length - 1])
                  trackTealEvent({
                    tealium_event: "carousel_click",
                    carousel_action: "left arrow",
                  })
                }
              }}
            >
              <Icon.Chevron
                css={[tw`m-2 h-8 inline lg:(m-4 h-16)`]}
                color="gray-700"
                direction="left"
              />
            </button>

            <button
              css={[
                tw` bg-transparent absolute top-1/2 right-2 mt-6 transform -translate-y-1/2 z-20 lg:(right-0) hover:(bg-gray-400 bg-opacity-75) focus:(outline-white bg-gray-400 bg-opacity-75) disabled:(opacity-0)`,
              ]}
              onClick={() => {
                if (selectedServiceIndex < services.length - 1) {
                  setSelectedServiceIndex(selectedServiceIndex + 1)
                  setSelectedService(services[selectedServiceIndex + 1])
                  trackTealEvent({
                    tealium_event: "carousel_click",
                    carousel_action: "right arrow",
                  })
                } else {
                  setSelectedServiceIndex(0)
                  setSelectedService(services[0])
                  trackTealEvent({
                    tealium_event: "carousel_click",
                    carousel_action: "right arrow",
                  })
                }
              }}
            >
              <Icon.Chevron
                css={[tw`m-2 h-8 inline lg:(m-4 h-16)`]}
                color="gray-700"
                direction="right"
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
