import { graphql, PageProps } from "gatsby"
import React, { useContext, useEffect, useRef } from "react"
import { Layout } from "../components/atoms/Layout"
import Benefits from "../components/molecules/Benefits/Benefits"
import Coupons from "../components/molecules/Coupons/Coupons"
import {
  Brochure,
  ImageBlocks,
  ToyotaCare,
} from "../components/organisms/Series"
import VideoSection from "../components/molecules/VideoSection/Video"
import Services from "../components/organisms/Service/Services"
import ServicesInfo from "../components/organisms/Service/ServiceInfo"
import { SEO } from "../components/atoms/SEO"
import tw from "twin.macro"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { LocationContext } from "../contexts/Location"
import useDealers from "../hooks/useDealers"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import { getCTALinkValue, windowExists } from "../helpers"
import Header from "../components/molecules/Heros/Header"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import { RetentionSalesEventHero } from "../components/molecules/RetentionSalesEventHero"
import { Find } from "../components/molecules/Find"
import PartAndServiceHero from "../components/organisms/PartAndServiceHero/PartAndServiceHero"
import CtaBox from "../components/molecules/Heros/CtaBox"
import TabbedImageModule from "../components/molecules/TabbedImageModule/TabbedImageModule"

const ServicePage: React.FC<PageProps> = ({ data, location }: any) => {
  const { page } = data

  //Tealium

  const { handleTealView } = useTealiumView()
  const [{ dealers }] = useContext(LocationContext)
  const pageload = useRef(true)
  const { getDealerInfo } = useDealers()
  const [isOutOfArea] = useTealiumNoZip()

  useEffect(() => {
    if (windowExists() && location?.hash?.includes("#dealersNearYou")) {
      const dealersNearYouEl = document.getElementById("dealersNearYou")
      const dealersNearYouPosition = dealersNearYouEl.getBoundingClientRect()
      window.scroll({
        top:
          dealersNearYouPosition.top +
          window.innerHeight * 0.85 +
          window.scrollY,
        behavior: "smooth",
      })
    }
  }, [])

  useEffect(() => {
    if (pageload.current && isOutOfArea) {
      pageload.current = false
      handleTealView({
        page_name: "service",
        tealium_event: "parts_service_service",
        search_results: 0,
        dealer_code: "NOT SET",
        dealer_name: "NOT SET",
        page_type: "parts service",
      })
    }

    const dealerInfo = getDealerInfo()
    // Fire only on inital page load, and not every time dealers is updated
    if (pageload.current && dealers) {
      pageload.current = false
      handleTealView({
        page_name: "service",
        tealium_event: "parts_service_service",
        search_results: dealerInfo.searchResults,
        dealer_code: dealerInfo.dealerCodes,
        dealer_name: dealerInfo.dealerNames,
        page_type: "parts service",
      })
    }
  }, [dealers, isOutOfArea])

  return (
    <Layout tealium={{ label: "services" }}>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />
      <SanitySections data={page} page={page} />
    </Layout>
  )
}

const SanitySections = ({ data, page }: any): JSX.Element => {
  const retentionSalesEventData = data?.content?.find(
    (section: any) => section._type === "salesEventHeroPageBuilder"
  )?.salesEvent
  const retentionSalesEventHeroData = retentionSalesEventData?.salesEventActive
    ? retentionSalesEventData?.hero
    : null
  return (
    <>
      {data?.content?.map((section: any) => {
        switch (section?._type) {
          case "header":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero {...retentionSalesEventHeroData} />
              )
            }
            return (
              <Header
                section={section}
                includeLogo={true}
                aria-label="Toyota Service Centers Hero Section"
              />
            )
          case "partsAndServiceHero":
            if (retentionSalesEventHeroData) {
              return (
                <RetentionSalesEventHero {...retentionSalesEventHeroData} />
              )
            }
            return <PartAndServiceHero {...section} page={page} />
          case "infoSection":
            return <ServicesInfo {...section} />
          case "servicesSection":
            return <Services {...section} />
          case "couponsSection":
            return <Coupons {...section} />
          case "tabbedImageModule":
            return <TabbedImageModule {...section} />
          case "benefitsSection":
            return <Benefits {...section} title="Service" />
          case "videoSection":
            return <VideoSection {...section} />
          case "imageBlocks":
            return <ImageBlocks {...section} />
          case "imageWithText":
            return <ToyotaCare {...section} />
          case "dealersNearYouSection":
            return (
              <DealersNearYou
                type="default"
                {...section}
                ctaAnalyticsId="schedule service"
              />
            )
          case "imageBlocksWithDescriptionSection":
            return <ImageBlocksWithDescription section={section} />
          case "salesEventImageWithTextPageBuilder":
            if (!section?.salesEvent?.salesEventActive) return null
            return (
              <ToyotaCare
                {...section?.salesEvent?.imageWithText}
                imagePosition={section.imagePosition}
              />
            )
          case "brochure":
            return <Brochure {...section} />
        }
      })}
    </>
  )
}

export const query = graphql`
  query ServicesPageQuery($id: String) {
    page: sanityPageServices(id: { eq: $id }) {
      id
      language
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      content {
        ...HeaderData
        ...InfoSectionData
        ...ServicesSectionData
        ...CouponsSectionData
        ...TabbedImageModuleData
        ...BenefitsSectionData
        ...VideoSectionData
        ...ServiceImageBlocksData
        ...ToyotaCareData
        ...DealersNearYouData
        ...CallToActionBoxData
        ...ImageBlocksWithDescriptionData
        ...RetentionSalesEventImageWithTextData
        ...RetentionSalesEventHeroData
        ...PartsAndServiceHeroData
        ...BrochureData
      }
      disclaimers {
        code
        disclaimer
      }
    }
  }
`

export default ServicePage
